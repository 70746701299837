import {HTMLAttributes} from "react"
import El from "./El"
import {css, cx} from "@emotion/css"
import {SubTitleProps} from "./SubTitle"

export interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  level?: 'l1' | 'l2' | 'l3'
}

const Title = ({level = 'l2', children, className}: SubTitleProps) => {
  return <div
    className={cx(createLevelStyles(level), css`font-weight: 600;`, className)}
  >
    {children}
  </div>
}

const createLevelStyles = (level: 'l1' | 'l2' | 'l3') => {
  if (level === `l1`) {
    return css`
      font-size: 47.78px;
      line-height: 48px;  
    `
  } else if (level === `l2`) {
    return css`
      font-size: 39.81px;
      line-height: 44px;
    `
  } else {
    return css`
      font-size: 33.18px;
      line-height: 40px;
    `
  }
}

export default Title